import {combineReducers} from 'redux'
import ExecutivesListStateReducer from './executivesListReducer'
import {FeaturedExecutivesListStateReducer} from './executivesListReducer'




const AllReducers = combineReducers ({
   ExecutivesListStateReducer,
   FeaturedExecutivesListStateReducer
})

export default AllReducers