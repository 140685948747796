import './provenResultsCarousel.scss'

const CarouselItem = () => {
    return(
        <div className="resultItem">
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.1301 20.19L10.5001 16.36C12.0701 15.78 13.5401 15 14.9001 14.09L12.1301 20.19ZM4.64006 10.5L0.810059 8.87L6.91006 6.1C6.00006 7.46 5.22006 8.93 4.64006 10.5ZM20.6101 0.390002C20.6101 0.390002 15.6601 -1.731 10.0001 3.93C7.81006 6.12 6.50006 8.53 5.65006 10.64C5.37006 11.39 5.56006 12.21 6.11006 12.77L8.24006 14.89C8.79006 15.45 9.61006 15.63 10.3601 15.35C12.8786 14.3897 15.1654 12.9072 17.0701 11C22.7301 5.34 20.6101 0.390002 20.6101 0.390002ZM13.5401 7.46C12.7601 6.68 12.7601 5.41 13.5401 4.63C14.3201 3.85 15.5901 3.85 16.3701 4.63C17.1401 5.41 17.1501 6.68 16.3701 7.46C15.5901 8.24 14.3201 8.24 13.5401 7.46ZM7.88006 14.53L6.47006 13.12L7.88006 14.53ZM5.24006 20L8.88006 16.36C8.54006 16.27 8.21006 16.12 7.91006 15.91L3.83006 20H5.24006ZM1.00006 20H2.41006L7.18006 15.24L5.76006 13.83L1.00006 18.59V20ZM1.00006 17.17L5.09006 13.09C4.88006 12.79 4.73006 12.47 4.64006 12.12L1.00006 15.76V17.17Z"
            fill="#9E741B"
          />
        </svg>
        <p className="resultText">Launching a new product or program</p>
      </div>
        
    )
}

export default CarouselItem