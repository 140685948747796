import "./howToHireAnExecutive.scss";
import statement from "../../assets/statement.jpg";

const StepFour = () => {
  return (
    <div className="whiteSelectionStep">
      <div className="desktopUI">
      <img src={statement} alt="state" />
      <div>
        <p className="selectionStepNumber">Step 4.</p>
        <p className="selectionStepHeading">Statement of Work</p>
        <p className="selectionStepDescription">
          We develop and send you a customized statement of work with agreed-to
          time frames and cost to achieve your desired goals.
        </p>
      </div>
      </div>
      <div className="mobileUI">
        <div>
          <p className="selectionStepNumber">Step 4.</p>
          <p className="selectionStepHeading">Statement of Work</p>
        </div>
        <img src={statement} alt="state" />
        <p className="selectionStepDescription">
          We develop and send you a customized statement of work with agreed-to
          time frames and cost to achieve your desired goals.
        </p>
      </div>
    </div>
  );
};

export default StepFour;
