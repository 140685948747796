
import "./provenResultsCarousel.scss";
import CarouselItem9 from "./carouselItem9";
import CarouselItem8 from "./carouselItem8";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

const ProvenResultsCarousel4 = () => {
  return (

        <Splide
          options={{
            speed: 2000,
            autoplay: true,
            interval: 6000,
            arrows: false,
            pauseOnHover: true
          }}
        >
          <SplideSlide>
            <CarouselItem8 />
          </SplideSlide>
          <SplideSlide>
            <CarouselItem9 />
          </SplideSlide>
        </Splide>
      
  );
};

export default ProvenResultsCarousel4;
