import './provenResultsCarousel.scss'

const CarouselItem8 = () => {
    return(
      <div className="resultItem">
      <svg
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.28114 8C8.28114 8.69619 8.5577 9.36387 9.04999 9.85616C9.54227 10.3484 10.2099 10.625 10.9061 10.625C11.6023 10.625 12.27 10.3484 12.7623 9.85616C13.2546 9.36387 13.5311 8.69619 13.5311 8C13.5311 7.30381 13.2546 6.63613 12.7623 6.14384C12.27 5.65156 11.6023 5.375 10.9061 5.375C10.2099 5.375 9.54227 5.65156 9.04999 6.14384C8.5577 6.63613 8.28114 7.30381 8.28114 8ZM21.0827 7.39531C18.8608 2.71484 15.5022 0.359375 10.9999 0.359375C6.4952 0.359375 3.13895 2.71484 0.917079 7.39766C0.827959 7.58637 0.781738 7.79247 0.781738 8.00117C0.781738 8.20987 0.827959 8.41598 0.917079 8.60469C3.13895 13.2852 6.49755 15.6406 10.9999 15.6406C15.5046 15.6406 18.8608 13.2852 21.0827 8.60234C21.2632 8.22266 21.2632 7.78203 21.0827 7.39531ZM10.9061 12.125C8.62802 12.125 6.78114 10.2781 6.78114 8C6.78114 5.72188 8.62802 3.875 10.9061 3.875C13.1843 3.875 15.0311 5.72188 15.0311 8C15.0311 10.2781 13.1843 12.125 10.9061 12.125Z"
          fill="#9E741B"
        />
      </svg>
      <p className="resultText">
        Reinvigorating the vision and purpose of your organization
      </p>
    </div>
    )
}

export default CarouselItem8