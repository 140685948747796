import React from 'react'
import './executiveProfileCard.scss'
import ClickOutsideProfileCard from "./clickOutsideProfileCard"

const ExecutiveProfileCard = ({setShowProfileCard, execName, bio, image, position, industry, speciality}) => {
    return(
        <div className="executiveProfileCardContainer">
            <ClickOutsideProfileCard execName={execName} image={image} bio={bio} position={position} industry={industry} speciality={speciality} setShowProfileCard={setShowProfileCard} />
        </div>
    )
}

export default ExecutiveProfileCard