
import "./provenResultsCarousel.scss";
import CarouselItem4 from "./carouselItem4";
import CarouselItem3 from "./carouselItem3";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

const ProvenResultsCarousel2 = () => {
  return (
     
     
        <Splide
          options={{
            speed: 2000,
            autoplay: true,
            interval: 6000, 
            arrows: false,
            pauseOnHover: true
          }}
        >
          <SplideSlide>
            <CarouselItem3 />
          </SplideSlide>
          <SplideSlide>
            <CarouselItem4 />
          </SplideSlide>
        </Splide>
      
  );
};

export default ProvenResultsCarousel2;
