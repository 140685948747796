import React, { useState } from "react";
import './becomeHireAnExecutive.scss'
import RegisterExecutiveCard from '../registerToBecomeAnExecutive/registerExecutiveCard'
import BookExecutiveCard from '../BookExecutive/bookExecutiveCard'

const BecomeHireAnExecutive = ({heading, paragraph, button}) => {

  const [showRegisterCard, setShowRegisterCard] = useState(false)
  const [showBookExecutiveCard, setShowBookExecutiveCard] = useState(false)

  const handleButtonClick = () => {
    if (button === "Register to be an executive"){
        setShowRegisterCard(true)
        console.log("Register modal true")
    }
    else{
      setShowBookExecutiveCard(true)
      console.log("Text not register")
    }
  }

    return (
      <div className="becomeAnExecutiveContainer">
        <div className="becomeAnExecutiveElementsContainer">
          <p className="becomeHeading">{heading}</p>
          <p className="becomeParagraph">
           {paragraph}
          </p>
          <div className="buttonContainer">
            <button className="register" onClick={() => handleButtonClick()}>{button}</button>
          </div>
        </div>
        {showRegisterCard ? <RegisterExecutiveCard setShowRegisterCard={setShowRegisterCard} /> : null}
        {showBookExecutiveCard ? <BookExecutiveCard profileCard={false} setShowBookExecutiveCard={setShowBookExecutiveCard} /> : null}
      </div>
    );
};

export default BecomeHireAnExecutive