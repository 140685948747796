import "./provenResultsCarousel.scss";

import CarouselItem7 from "./carouselItem7";
import CarouselItem6 from "./carouselItem6";
import CarouselItem5 from "./carouselItem5";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

const ProvenResultsCarousel3 = () => {
  return (
    <Splide
      options={{
        speed: 2000,
        autoplay: true,
        interval: 3000,
        arrows: false,
        pauseOnHover: true,
      }}
    >
      <SplideSlide>
        <CarouselItem5 />
      </SplideSlide>
      <SplideSlide>
        <>
          <CarouselItem6 />
          <CarouselItem7 />
        </>
      </SplideSlide>
    </Splide>
  );
};

export default ProvenResultsCarousel3;
