import React, {useLayoutEffect} from "react";
import NavBar from "../../components/navBar/navBarMeet";
import Footer from "../../components/footer/footer";
import BecomeHireAnExecutive from "../../components/BecomeHireAnExecutive/becomeHireAnExecutive";
import "./about.scss";

const AboutPage = () => {

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className="aboutPageContainer">
      <div className="aboutPageElementsContainer">
        <NavBar />
        <div className="aboutPageBodyContainer">
          <p className="pageHeading">
            Our executives serve business builders <br />
            like yourselves to achieve their goals
          </p>
          <p className="mobilePageHeading">
            Our executives serve business builders 
            like yourselves to achieve their goals
          </p>
        </div>
        <div className="urgentGrowthContainer">
          <div className="title">
            Urgent Growth <br />
            Challenge? <br />
            We are ready for it.
          </div>
          <div className="caption">
            We are trusted by companies of all sizes to meet their needs.
            <br /> Work with our executives to complement and extend the <br />{" "}
            capabilities of your team for pressing initiatives that may be{" "}
            <br />
            short-term, temporary or intermittent.
            <br />
            <p>
              We’re a one-of-a-kind collection of experienced, hands- on, <br />
              energetic executives who have made it our life’s work to help{" "}
              <br />
              YOU achieve business success.
            </p>
          </div>
        </div>
        <div className="whatYouNeedHelpContainer">
          <div className="halfPageHeading">What might you need help with?</div>
          <div className="speakWithUsSection">
            <div className="rightSection">
              <p className="launching">
                Launching a new product or program?
              </p>
              <p className="speakWithUs">Speak with us.</p>
              <p className="speakWithUsCaption">
                Gig Office executives are proven performers and doers
                who can integrate within your team quickly.
              </p>
            </div>
            <div className="leftSection">
              <p className="launching">
                Entering a channel requiring a whole new skill set?
              </p>
                <p className="speakWithUs">Speak with us.</p>
                <p className="speakWithUsCaption">
                  Our wide range of competencies and capabilities
                  can help you quickly fill gaps in finance, operations,
                  strategy or any other skill you may want to tap in.
                </p>
            </div>
          </div>
          <div className="faqHeading">FAQs</div>
          <div className="faqItem">
            <div className="question">What is Gig Office?</div>
            <div className="answer">
              Gig Office is a platform that enables anyone or companies seeking
              specialized expertise to connect with expert executives and work
              with <br />
              them for a defined period of time with clear deliverables.
            </div>
          </div>
          <div className="faqItem">
            <div className="question">How does Gig Office work?</div>
            <div className="answer">
              We designed the platform in a way that we can understand your
              needs and requirements deeply, and from then on present you with
              an <br /> executive or executives that meet those . We kick off
              with a discovery call to better understand you rneeds, and
              thereafter make a <br /> recommendation of one of our executives
              that can help you.
            </div>
          </div>
          <div className="faqItem">
            <div className="question">
              How much does it cost to hire an Executive?
            </div>
            <div className="answer">
              Pricing is dependent on your project scope and the executive’s
              expertise. We recommend sharing your budgets before hand,
              alongside <br /> your needs and requirements.
            </div>
          </div>
        </div>
        <BecomeHireAnExecutive
          heading="Hire an Executive"
          paragraph="If you are interested in hiring an executive, click on the button below."
          button="Hire an executive"
        />
        <Footer />
      </div>
    </div>
  );
};

export default AboutPage;
