import React, {useState} from 'react'
import './executiveProfileCard.scss'
import econet from "../../assets/companies/econet.png"
import mtn from "../../assets/companies/mtn.png"
import dstv from "../../assets/companies/dstv.png"
import google from "../../assets/companies/google.png"
import close from "../../assets/close.png"
import onClickOutside from "react-onclickoutside";
import BookExecutiveCard from '../BookExecutive/bookExecutiveCard'

function ClickOutsideProfileCard({setShowProfileCard, execName, bio, image, position, industry, speciality}){

    ClickOutsideProfileCard.handleClickOutside = () => setShowProfileCard(false);
    const [showBookExecutiveCard, setShowBookExecutiveCard] = useState(false)

    return(
            <div className="executiveProfileCardElementsContainer">
                <div className="goBackButton">
                    <img onClick={()=>setShowProfileCard(false)} src={close} alt="Back Arrow" />
                </div>
                <div className="headerRow">
                    <div className="executiveImageContainer">
                        <img src={image} alt="dp" />
                    </div>
                    <div className="executiveBio">
                        <p className="executiveName">{execName}</p>
                        <p className="executivePosition">{position}</p>
                        <p className="executiveSpeciality">{industry}</p>
                        {/* <p className="executiveExperience">Experience</p>
                        <div className="logoRow">
                            <img src={econet} alt="companyLogo" />
                            <img src={google} alt="companyLogo" />
                            <img src={mtn} alt="companyLogo" />
                            <img src={dstv} alt="companyLogo" />
                        </div> */}
                    </div>
                </div>
                <div className="bodyRow">
                   

                    <div className="executiveAbout">
                        <p className="hireMeHeading">Biography</p>
                        {bio.map((paragraph) => {
                            return(<p className="hireMeText">{paragraph}</p>);
                        })}
                        {/* <p className="hireMeHeading">What I do</p>
                        <p className="hireMeText">All of them and still counting...</p> */}
                        <p className="hireMeHeading">My speciality</p>
                        {speciality.map((paragraph) => {
                            return(<p className="hireMeText">{paragraph}</p>);
                        })}
                        {/* <p className="hireMeHeading">Industries</p>
                        <p className="hireMeText">All of them and still counting...</p> */}
                    </div>

                    <div className="profileCardButtonContainer">
                        <button onClick={()=>setShowBookExecutiveCard(true)} className="executiveProfileCardButton">Book Executive</button>
                    </div>
                </div>
                {showBookExecutiveCard ? <BookExecutiveCard profileCard={true} execName={execName} setShowBookExecutiveCard={setShowBookExecutiveCard} /> : null}
            </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => ClickOutsideProfileCard.handleClickOutside,

};

export default onClickOutside(ClickOutsideProfileCard, clickOutsideConfig);