import './provenResultsCarousel.scss'

const CarouselItem6 = () => {
    return(
      <div className="resultItem">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 22.5H16.5V3H22.5V22.5ZM18 21H21V4.5H18V21Z"
          fill="#9E741B"
        />
        <path d="M15 22.5H9V9H15V22.5Z" fill="#9E741B" />
        <path d="M7.5 22.5H1.5V13.5H7.5V22.5Z" fill="#9E741B" />
      </svg>
      <p className="resultText">
        Providing Leadership with CFO skills to optimize operations
      </p>
    </div>
        
    )
}

export default CarouselItem6