import './executiveCard.scss'
import { useState } from 'react'
import ExecutiveProfileCard from '../executiveProfileCard/executiveProfileCard'

const ExecutiveCard = ({picture, name, bio, title, areas, sectors, speciality}) => {

  const [showProfileCard, setShowProfileCard] = useState(false)

  return(
      <div className="executiveCardContainer">
      <div className="exec-card-wrapper">
        <div className='img-container'>
          <img alt="dp" src={picture}/>
        </div>
          <div className="black-part">
           <div className="name-container">
              {name}
           </div>
           <div className="title-container">
              {title}
           </div>
           <div className="area-and-sector">
             <div className="title">Areas</div>
             <div className="fields">{areas}</div>
           </div>
           <div className="area-and-sector">
             <div className="title">Sectors</div>
             <div className="fields">{sectors}</div>
           </div>
           <button onClick={()=>setShowProfileCard(true)} >Read More</button>
          </div>
      </div>
    { showProfileCard ? <ExecutiveProfileCard execName={name} image={picture} bio={bio} position={title} industry={areas} speciality={speciality} setShowProfileCard={setShowProfileCard}/> : null}
      </div>
  )
}
export default ExecutiveCard