import React from 'react'
import './registerExecutiveCard.scss'
import ClickOutsideRegisterExecutiveCard from './clickOutsideRegisterExecutiveCard'

const RegisterExecutiveCard = ({setShowRegisterCard}) => {

    return(
      <div className="registerExecutiveCardContainer">
            <ClickOutsideRegisterExecutiveCard setShowRegisterCard={setShowRegisterCard} />
      </div>   
  );
};

export default RegisterExecutiveCard;
