import './provenResultsCarousel.scss'

const CarouselItem9 = () => {
    return(
      <div className="resultItem">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z"
          fill="#9E741B"
        />
        <path
          d="M19.5 21H4.5C4.1023 20.9996 3.721 20.8414 3.43978 20.5602C3.15856 20.279 3.0004 19.8977 3 19.5V4.5C3.0004 4.1023 3.15856 3.721 3.43978 3.43978C3.721 3.15856 4.1023 3.0004 4.5 3H19.5C19.8977 3.0004 20.279 3.15856 20.5602 3.43978C20.8414 3.721 20.9996 4.1023 21 4.5V19.5C20.9996 19.8977 20.8414 20.279 20.5602 20.5602C20.279 20.8414 19.8977 20.9996 19.5 21ZM4.5 4.5V19.5H19.5007L19.5 4.5H4.5Z"
          fill="#9E741B"
        />
      </svg>
      <p className="resultText">
        Filling in a gap in management providing diversity and go to
        market experience to the team
      </p>
    </div>
        
    )
}

export default CarouselItem9