import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux';
import AllReducers from './redux/reducers';
import {createBrowserHistory} from 'history'
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
// The store now has the ability to accept thunk functions in `dispatch`
const store = createStore(AllReducers, composedEnhancer)

const history = createBrowserHistory()

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
     <App history={history}/>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);
