import "./howToHireAnExecutive.scss";
import call from "../../assets/discoveryCall.jpg";

const StepTwo = () => {
  return (
    <div className="whiteSelectionStep">
      <div className="desktopUI">
        <img alt="call" src={call} />
        <div>
          <p className="selectionStepNumber">Step 2.</p>
          <p className="selectionStepHeading">Discovery Call</p>
          <p className="selectionStepDescription">
            Fill in a form outlining your needs and requirements, and we will
            follow-up with next steps via a call or email.We schedule a time to
            discuss your unique challenges and opportunities for growth with you.
          </p>
        </div>
      </div>
      <div className="mobileUI">
        <div>
          <p className="selectionStepNumber">Step 2.</p>
          <p className="selectionStepHeading">Discovery Call</p>
        </div>
        <img alt="call" src={call} />
        <p className="selectionStepDescription">
            Fill in a form outlining your needs and requirements, and we will
            follow-up with next steps via a call or email.We schedule a time to
            discuss your unique challenges and opportunities for growth with you.
          </p>
      </div>
    </div>
  );
};

export default StepTwo;
