import "./howToHireAnExecutive.scss";
import start from '../../assets/quickStart.jpg'

const StepFive = () => {
  return (
    <div className="greySelectionStep">
      <div className="desktopUI">
      <img src={start} alt="start"/>
      <div>
         <p className="selectionStepNumber">Step 5.</p>
      <p className="selectionStepHeading">Quick Start</p>
      <p className="selectionStepDescription">
        <b>YOU ARE ALL SET !</b> <br/> Your executive gets started in days (working virtually or in person
        depending on agreement).
      </p>
      </div>
      </div>
      <div className="mobileUI">
        <div>
          <p className="selectionStepNumber">Step 5.</p>
          <p className="selectionStepHeading">Quick Start</p>
        </div>
        <img src={start} alt="start"/>
        <p className="selectionStepDescription">
        <b>YOU ARE ALL SET !</b> <br/> Your executive gets started in days (working virtually or in person
        depending on agreement).
        </p>
      </div>
    </div>
  );
};

export default StepFive;
