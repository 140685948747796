import React, {useState} from "react";
import "./home.scss";
import { Link } from "react-router-dom";
import NavBar from "../../components/navBar/navBarMeet";
import Footer from "../../components/footer/footer";
import HomeHero from "../../components/homeHero/homeHero";
import ExecutiveCard from "../../components/executiveCard/executiveCard";
import { useSelector } from "react-redux";
import BecomeHireAnExecutive from "../../components/BecomeHireAnExecutive/becomeHireAnExecutive";
import ProvenResultsCarousel from "../../components/provenResultsCarousel/provenResultsCarousel";
import ProvenResultsCarousel4 from "../../components/provenResultsCarousel/provenResultsCarousel4";
import ProvenResultsCarousel3 from "../../components/provenResultsCarousel/provenResultsCarousel3";
import ProvenResultsCarousel2 from "../../components/provenResultsCarousel/provenResultsCarousel2";
import HowToHireAnExecutive from "../../components/howToHireAnExecutive/howToHireAnExecutive";
import BookExecutiveCard from "../../components/BookExecutive/bookExecutiveCard";

const ProvenResults = () => {
  return (
    <div className="provenResultsContainer">
      <p className="heading">Bring our proven results to your company</p>
      <p className="introParagraph">
        Our executives bring strategic insight and flawless execution to the
        disciplines of business that matter most to your company. Our
        professionals draw on a wealth of industry knowledge to see your
        business in new ways. Speak with us if you are searching for flexible
        executives. These are some ways in which we have helped some leaders:
      </p>
      <div className="provenResultsFlexContainer">
        <div className="flexItem">
          Product
          <ProvenResultsCarousel />
        </div>
        <div className="flexItem">
          Market
          <ProvenResultsCarousel2 />
        </div>
      </div>
      <div className="provenResultsFlexContainer">
        <div className="flexItem">
          Strategy
          <ProvenResultsCarousel3 />
        </div>
        <div className="flexItem">
          People
          <ProvenResultsCarousel4 />
        </div>
      </div>
    </div>
  );
};

const ConnectBusinesses = () => {
  return (
    <div className="connectBusinessesContainer">
      <div className="connectBusinessesElementsContainer">
        <p className="connectHeading">
          We connect business builders in Africa with the continent’s best
          operators and executives
        </p>
        <p className="connectParagraph">
          Our mission is to empower business builders in Africa with access to
          the continent's best executives without a long term commitment. Gig
          Office enables anyone or any brand to work directly with industry
          leaders with a proven track record.
        </p>
      </div>
    </div>
  );
};

const YouAreSmart = () => {
  return (
    <div className="youAreSmartContainer">
      <div className="youAreSmartElementsContainer">
        <p className="connectHeading">
          You are smart but it’s impossible to know everything.
        </p>
        <p className="connectParagraph">
          We are trusted by companies of all sizes to plug the knowledge gaps.
          Our executives serve business builders like you to achieve your goals.
          They bring short term specialized expertise to complement and extend
          the capabilities of your team for pressing initiatives. <br />
          <br />
          Discover a trusted executive with the expertise and skills you
          require.
        </p>
      </div>
    </div>
  );
};

const SelectYourExecutive = () => {

  const [showBookExecutiveCard, setShowBookExecutiveCard] = useState(false)

  return (
    <div className="selectYourExecutiveContainer">
      <div className="selectYourExecutiveElementsContainer">
        <div className="leftSection">
          <p className="heading">
            How To
            <br />
            Hire An Executive
          </p>
          {/* <img alt="web" src={Web} /> */}
        </div>
        <div className="rightSection">
          <div className="rightSectionHeight">
             <HowToHireAnExecutive/>
          </div>
         
        </div>
        
        {/* <div className="rightSection">
          <div className="greySelectionStep">
            <p className="selectionStepNumber">1.</p>
            <p className="selectionStepHeading">Expression of Interest</p>
            <p className="selectionStepDescription">
              Fill in a form outlining your needs and requirements, and we will
              follow-up with next steps via a call or email.
            </p>
          </div>
          <div className="whiteSelectionStep">
            <p className="selectionStepNumber">2.</p>
            <p className="selectionStepHeading">Discovery Call</p>
            <p className="selectionStepDescription">
              Fill in a form outlining your needs and requirements, and we will
              follow-up with next steps via a call or email.We schedule a time
              to discuss your unique challenges and opportunities for growth
              with you.
            </p>
          </div>
          <div className="greySelectionStep">
            <p className="selectionStepNumber">3.</p>
            <p className="selectionStepHeading">Executive Match</p>
            <p className="selectionStepDescription">
              We help match your situation and requirements with one or more of
              our executives, working with you to ensure the right expertise,
              availability, affordability and cultural fit.
            </p>
          </div>
          <div className="whiteSelectionStep">
            <p className="selectionStepNumber">4.</p>
            <p className="selectionStepHeading">Statement of Work</p>
            <p className="selectionStepDescription">
              We develop and send you a customized statement of work with
              agreed-to time frames and cost to achieve your desired goals.
            </p>
          </div>
          <div className="greySelectionStep">
            <p className="selectionStepNumber">5.</p>
            <p className="selectionStepHeading">Quick Start</p>
            <p className="selectionStepDescription">
              Your executive gets started in days (working virtually or in
              person depending on agreement).
            </p>
          </div>
        </div> */}
      </div>
      <div className="bookAnExecutiveRow">
        <button className="bookAnExecutiveButton" onClick={()=>setShowBookExecutiveCard(true)}>
          Book an Executive
        </button>
      </div>

      {showBookExecutiveCard ? <BookExecutiveCard setShowBookExecutiveCard={setShowBookExecutiveCard}/> : null}
    </div>
  );
};

const Description = () => {
  return (
    <div className="descriptionContainer">
      <div className="leftSection">
        <p className="descriptionHeading">
          Gig Office instantly provides you with a part-time experienced
          executive who fits your requirements
        </p>
      </div>
      <div className="rightSection">
        <p className="descriptionParagraph">
          Today's business challenges are more complex than ever before and
          require agility. As a leader, you must continually adapt your product
          or service to meet the needs of today's stakeholders and markets,
          while delivering a healthy return.
          <br />
          <br />
          Gig Office enables you to access the skills of a top executive with
          hands-on proficiency, delivered on-time and within budget.
          <br />
        </p>
      </div>
    </div>
  );
};

const FeaturedExecutives = () => {
  const exec = useSelector((state) => state.FeaturedExecutivesListStateReducer);

  return (
    <div className="featuredExecutivesContainer">
      <p className="heading">Featured Executives</p>
      <div className="executives-grid-container">
        <div className="parent">
          {exec.executives.map((executive) => (
            <ExecutiveCard
              name={executive.name}
              bio={executive.bio}
              picture={executive.image}
              title={executive.title}
              areas={executive.areas}
              sectors={executive.sectors}
              speciality={executive.speciality}
            />
          ))}
        </div>
      </div>
      <div className="bookAnExecutiveRow">
        <Link to="/executives" className="bookAnExecutiveButton">
          View all executives
        </Link>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <div className="homePageContainer">
      <NavBar />
      <HomeHero />
      <ProvenResults />
      <ConnectBusinesses />
      <YouAreSmart />
      <SelectYourExecutive />
      <Description />
      <FeaturedExecutives />
      <BecomeHireAnExecutive
        heading="Become An Executive"
        paragraph="If you are interested in beccoming an executive, click on the button
            below"
        button="Register to be an executive"
      />
      <Footer />
    </div>
  );
};

export default Home;
