import "./provenResultsCarousel.scss";

import CarouselItem2 from "./carouselItem2";
import CarouselItem from "./carouselItem";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

const ProvenResultsCarousel = () => {
  return (
     
        <Splide
          options={{
            speed: 2000,
            autoplay: true,
            interval: 3000,
            arrows: false,
            pauseOnHover: true
          }}
        >
          <SplideSlide>
            <CarouselItem />
          </SplideSlide>
          <SplideSlide>
            <CarouselItem2 />
          </SplideSlide>
        </Splide>
      
     
  );
};

export default ProvenResultsCarousel;
