import './provenResultsCarousel.scss'

const CarouselItem3 = () => {
    return(
      <div className="resultItem">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.6315 0C1.4425 0 1.274 0.131 1.209 0.328L0.0550001 3.8335C0.0185628 3.94436 -2.6634e-06 4.06031 2.86589e-10 4.177V5.5715C2.86589e-10 6.36 0.5755 7 1.2855 7C1.9955 7 2.5715 6.36 2.5715 5.5715C2.5715 6.3605 3.147 7 3.857 7C4.567 7 5.143 6.36 5.143 5.5715C5.143 6.3605 5.7185 7 6.4285 7C7.1385 7 7.7135 6.361 7.7145 5.5725C7.7145 6.361 8.29 7 9 7C9.71 7 10.2855 6.36 10.2855 5.5715C10.2855 6.3605 10.8615 7 11.5715 7C12.2815 7 12.8565 6.361 12.857 5.5725C12.8575 6.361 13.433 7 14.143 7C14.853 7 15.4285 6.36 15.4285 5.5715C15.4285 6.3605 16.004 7 16.7145 7C17.4245 7 18 6.36 18 5.5715V4.177C18 4.06031 17.9814 3.94436 17.945 3.8335L16.791 0.3285C16.726 0.131 16.5575 0 16.3685 0H1.6315Z"
          fill="#9E741B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.5 7.7385V11H0.75C0.683696 11 0.620107 11.0263 0.573223 11.0732C0.526339 11.1201 0.5 11.1837 0.5 11.25V11.75C0.5 11.8163 0.526339 11.8799 0.573223 11.9268C0.620107 11.9737 0.683696 12 0.75 12H17.25C17.3163 12 17.3799 11.9737 17.4268 11.9268C17.4737 11.8799 17.5 11.8163 17.5 11.75V11.25C17.5 11.1837 17.4737 11.1201 17.4268 11.0732C17.3799 11.0263 17.3163 11 17.25 11H16.5V7.7385C16.1337 7.69907 15.7866 7.555 15.5 7.3235V11H2.5V7.3235C2.21344 7.555 1.86627 7.69907 1.5 7.7385ZM15.737 6.5C15.786 6.563 15.8395 6.6215 15.897 6.674C16.067 6.83 16.2735 6.938 16.5 6.9805V6.5H15.737ZM1.5 6.98V6.5H2.263C2.21476 6.56249 2.16123 6.6207 2.103 6.674C1.933 6.83 1.7265 6.938 1.5 6.9805V6.98ZM1.25 12.5C1.1837 12.5 1.12011 12.5263 1.07322 12.5732C1.02634 12.6201 1 12.6837 1 12.75V17.5C1 17.6326 1.05268 17.7598 1.14645 17.8536C1.24021 17.9473 1.36739 18 1.5 18H16.5C16.6326 18 16.7598 17.9473 16.8536 17.8536C16.9473 17.7598 17 17.6326 17 17.5V12.75C17 12.6837 16.9737 12.6201 16.9268 12.5732C16.8799 12.5263 16.8163 12.5 16.75 12.5H1.25Z"
          fill="#9E741B"
        />
        <path
          d="M3 9.75C3 9.6837 3.02634 9.62011 3.07322 9.57322C3.12011 9.52634 3.1837 9.5 3.25 9.5H4.75C4.8163 9.5 4.87989 9.52634 4.92678 9.57322C4.97366 9.62011 5 9.6837 5 9.75V10.75C5 10.8163 4.97366 10.8799 4.92678 10.9268C4.87989 10.9737 4.8163 11 4.75 11H3.25C3.1837 11 3.12011 10.9737 3.07322 10.9268C3.02634 10.8799 3 10.8163 3 10.75V9.75Z"
          fill="#9E741B"
        />
        <path
          d="M4 10.25C4 10.1837 4.02634 10.1201 4.07322 10.0732C4.12011 10.0263 4.1837 10 4.25 10H5.75C5.8163 10 5.87989 10.0263 5.92678 10.0732C5.97366 10.1201 6 10.1837 6 10.25V10.75C6 10.8163 5.97366 10.8799 5.92678 10.9268C5.87989 10.9737 5.8163 11 5.75 11H4.25C4.1837 11 4.12011 10.9737 4.07322 10.9268C4.02634 10.8799 4 10.8163 4 10.75V10.25Z"
          fill="#9E741B"
        />
        <path
          d="M8 10.25C8 10.4489 7.92098 10.6397 7.78033 10.7803C7.63968 10.921 7.44891 11 7.25 11C7.05109 11 6.86032 10.921 6.71967 10.7803C6.57902 10.6397 6.5 10.4489 6.5 10.25C6.5 10.0511 6.57902 9.86032 6.71967 9.71967C6.86032 9.57902 7.05109 9.5 7.25 9.5C7.44891 9.5 7.63968 9.57902 7.78033 9.71967C7.92098 9.86032 8 10.0511 8 10.25Z"
          fill="#9E741B"
        />
      </svg>
      <p className="resultText">
        Expanding into a market or new channel in their industry
      </p>
    </div>
        
    )
}

export default CarouselItem3