import './provenResultsCarousel.scss'

const CarouselItem2 = () => {
    return(
        <div className="resultItem">
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.75 11.9375C16.1019 11.9383 15.474 12.1626 14.972 12.5724C14.4699 12.9823 14.1246 13.5527 13.9941 14.1875H4.75C3.90462 14.1875 3.09387 13.8517 2.4961 13.2539C1.89832 12.6561 1.5625 11.8454 1.5625 11C1.5625 10.1546 1.89832 9.34387 2.4961 8.7461C3.09387 8.14833 3.90462 7.8125 4.75 7.8125H13.75C14.6948 7.8125 15.601 7.43717 16.2691 6.76907C16.9372 6.10097 17.3125 5.19483 17.3125 4.25C17.3125 3.30517 16.9372 2.39903 16.2691 1.73093C15.601 1.06283 14.6948 0.6875 13.75 0.6875H4.75C4.60082 0.6875 4.45774 0.746763 4.35225 0.852252C4.24676 0.957742 4.1875 1.10082 4.1875 1.25C4.1875 1.39918 4.24676 1.54226 4.35225 1.64775C4.45774 1.75324 4.60082 1.8125 4.75 1.8125H13.75C14.3965 1.8125 15.0165 2.06931 15.4736 2.52643C15.9307 2.98355 16.1875 3.60353 16.1875 4.25C16.1875 4.89647 15.9307 5.51645 15.4736 5.97357C15.0165 6.43069 14.3965 6.6875 13.75 6.6875H4.75C3.60625 6.6875 2.50935 7.14185 1.7006 7.9506C0.891851 8.75935 0.4375 9.85625 0.4375 11C0.4375 12.1437 0.891851 13.2406 1.7006 14.0494C2.50935 14.8581 3.60625 15.3125 4.75 15.3125H13.9941C14.1005 15.8339 14.3527 16.3145 14.7213 16.6983C15.09 17.0822 15.5599 17.3535 16.0767 17.4809C16.5934 17.6083 17.1356 17.5865 17.6404 17.418C18.1452 17.2495 18.5919 16.9412 18.9285 16.529C19.2651 16.1168 19.4778 15.6175 19.542 15.0892C19.6062 14.5609 19.5191 14.0253 19.291 13.5445C19.0629 13.0636 18.703 12.6574 18.2532 12.373C17.8034 12.0885 17.2822 11.9375 16.75 11.9375ZM16.75 16.4375C16.4162 16.4375 16.09 16.3385 15.8125 16.1531C15.535 15.9677 15.3187 15.7041 15.191 15.3958C15.0632 15.0874 15.0298 14.7481 15.0949 14.4208C15.16 14.0934 15.3208 13.7928 15.5568 13.5568C15.7928 13.3208 16.0934 13.16 16.4208 13.0949C16.7481 13.0298 17.0874 13.0632 17.3958 13.191C17.7041 13.3187 17.9677 13.535 18.1531 13.8125C18.3385 14.09 18.4375 14.4162 18.4375 14.75C18.437 15.1974 18.2591 15.6263 17.9427 15.9427C17.6263 16.2591 17.1974 16.437 16.75 16.4375Z"
            fill="#9E741B"
          />
        </svg>
        <p className="resultText">
          Turn around expertise to steer a product or company onto the right
          path
        </p>
      </div>
        
    )
}

export default CarouselItem2