import React, { useState } from "react";
import "./bookExecutiveCard.scss";
import close from "../../assets/close.png";
import axios from "axios";
import registration from "../../assets/registration.png";
import onClickOutside from "react-onclickoutside";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function ClickOutsideBookExecutiveCard({
  setShowBookExecutiveCard,
  profileCard,
  execName,
}) {
  ClickOutsideBookExecutiveCard.handleClickOutside = () =>
    setShowBookExecutiveCard(false);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [workEmail, setWorkEmail] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [location, setLocation] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [message, setMessage] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isError, setIsError] = useState(false);

  const notify = () => {
    setIsError(true);
  };

  const register = () => {
    //e.preventDefault()
    setProcessing(true);
    setIsError(false);
    console.log("pano");
    let data = new FormData();
    if (profileCard) {
      data.append("executive_name", execName);
    }
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("company_name", companyName);
    data.append("work_email", workEmail);
    data.append("contact_number", contactNumber);
    data.append("location", location);
    data.append("industry", industry);
    data.append("message", message);
    console.log({ data });
    axios({
      method: "POST",
      url: "https://gig-office-mail.herokuapp.com/hire",
      data,
    })
      .then((response) => {
        console.log(response);
        setSubmitSuccess(true);
        setProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitFail(true);
        setProcessing(false);
      });
  };

  return (
    <div className="registerExecutiveCardElementsContainer">
      <div className="goBackButton">
        <img
          onClick={() => setShowBookExecutiveCard(false)}
          src={close}
          alt="Back Arrow"
        />
      </div>
      <div className="registerFormContainer">
        <p className="formHeading">
          <img src={registration} alt="icon" />
          {profileCard ? `Book ${execName}` : "Book An Executive"}
        </p>
        {/* <form> */}
        <div className="inputItem">
          <label>First Name</label>
          <input
            type="text"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Company Name</label>
          <input
            type="text"
            placeholder="Company Name"
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Work Email</label>
          <input
            type="text"
            placeholder="Work Email"
            onChange={(e) => setWorkEmail(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Contact Number</label>
          <input
            type="text"
            placeholder="Contact Number"
            onChange={(e) => setContactNumber(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Industry</label>
          <input
            type="text"
            placeholder="Industry"
            onChange={(e) => setIndustry(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Location</label>
          <input
            type="text"
            placeholder="Location"
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Message</label>
          <textarea
            type="text"
            placeholder="Message"
            rows="4"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        {processing ? (
          <Loader
            className="spinner"
            type="Puff"
            color="#9E741B"
            height={70}
            width={70}
          />
        ) : null}

        {submitSuccess ? (
          <p className="successText">Application submitted successfully!</p>
        ) : null}

        {submitFail ? (
          <p className="failText">
            Oh no! Something went wrong with your submission. Please try again
            later.
          </p>
        ) : null}

        <button
          onClick={() =>
            firstName &&
            lastName &&
            companyName &&
            contactNumber &&
            message &&
            location &&
            industry &&
            workEmail
              ? register()
              : notify()
          }
          type="submit"
        >
          Book
        </button>
        {/* </form> */}
        {isError && <p style={{ color: "red" }}>Fill in the entire form</p>}
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => ClickOutsideBookExecutiveCard.handleClickOutside,
};

export default onClickOutside(
  ClickOutsideBookExecutiveCard,
  clickOutsideConfig
);
