import React, {useState} from 'react'
import './navBarMeet.scss'
import { NavLink } from "react-router-dom"
import logo from '../../assets/footer/logo.png'
import close from "../../assets/close.png"
import RegisterExecutiveCard from '../registerToBecomeAnExecutive/registerExecutiveCard'

const NavBar = () => {

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false)
    const [showRegisterCard, setShowRegisterCard] = useState(false)

    return(
        <div className="navBarContainer">
            <div className="navBarElementsContainer">
                <div className="meetLogoContainer">
                    <img className="logoImage" src={logo} alt="Gig Office Logo"/>
                </div>

                <div className="meetLinksContainer">
                    <NavLink exact activeClassName="link-active" to="/"><p>Home</p></NavLink>
                    <NavLink activeClassName="link-active" to="/about"><p>About</p></NavLink>
                    <NavLink activeClassName="link-active" to="/executives"><p>Meet our Executives</p></NavLink>
                    <div className="buttonContainer">
                        <button onClick={() => setShowRegisterCard(true)}>Join our Executive Network</button>
                    </div>
                </div>

                <div className="mobileMenuContainer">
                    <svg onClick={() => setMobileMenuVisible(true)} width="37" height="25" viewBox="0 0 37 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.875 0.75H36.125V4.66667H0.875V0.75ZM0.875 10.5417H36.125V14.4583H0.875V10.5417ZM0.875 20.3333H36.125V24.25H0.875V20.3333Z" fill="#3d2b13"/>
                    </svg>
                </div>

                {mobileMenuVisible ? <div className="mobileSideMenuContainer">
                    <div className="closeButton">
                        <img onClick={() => setMobileMenuVisible(false)} src={close} alt="Close Button" />
                    </div>
                    <div className="mobileSideLinksContainer">
                        <NavLink exact activeClassName="link-active" to="/"><p>Home</p></NavLink>
                        <NavLink activeClassName="link-active" to="/about"><p>About</p></NavLink>
                        <NavLink activeClassName="link-active" to="/executives"><p>Meet our Executives</p></NavLink>
                    </div>
                </div> : null}
            </div>
            {showRegisterCard ? <RegisterExecutiveCard setShowRegisterCard={setShowRegisterCard} /> : null}
        </div>
    )
}

export default NavBar