import './provenResultsCarousel.scss'

const CarouselItem4 = () => {
    return(
         <div className="resultItem">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 0C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4V10C0 11.0609 0.421427 12.0783 1.17157 12.8284C1.92172 13.5786 2.93913 14 4 14H10.17C10.4059 14.6672 10.8701 15.2296 11.4805 15.5876C12.0909 15.9457 12.8082 16.0765 13.5057 15.9568C14.2032 15.8371 14.836 15.4747 15.2921 14.9337C15.7482 14.3926 15.9984 13.7077 15.9984 13C15.9984 12.2923 15.7482 11.6074 15.2921 11.0663C14.836 10.5253 14.2032 10.1629 13.5057 10.0432C12.8082 9.92353 12.0909 10.0543 11.4805 10.4124C10.8701 10.7704 10.4059 11.3328 10.17 12H4C3.46957 12 2.96086 11.7893 2.58579 11.4142C2.21071 11.0391 2 10.5304 2 10V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H10C10.5304 2 11.0391 2.21071 11.4142 2.58579C11.7893 2.96086 12 3.46957 12 4H14C14 2.93913 13.5786 1.92172 12.8284 1.17157C12.0783 0.421427 11.0609 0 10 0H4ZM16 20C17.0609 20 18.0783 19.5786 18.8284 18.8284C19.5786 18.0783 20 17.0609 20 16V10C20 8.93913 19.5786 7.92172 18.8284 7.17157C18.0783 6.42143 17.0609 6 16 6H9.83C9.5941 5.33279 9.12993 4.77045 8.51952 4.41237C7.90911 4.05429 7.19176 3.92353 6.49427 4.0432C5.79677 4.16288 5.16404 4.52527 4.70789 5.06634C4.25175 5.60741 4.00157 6.29231 4.00157 7C4.00157 7.70769 4.25175 8.39259 4.70789 8.93366C5.16404 9.47473 5.79677 9.83712 6.49427 9.9568C7.19176 10.0765 7.90911 9.94571 8.51952 9.58763C9.12993 9.22955 9.5941 8.66721 9.83 8H16C16.5304 8 17.0391 8.21071 17.4142 8.58579C17.7893 8.96086 18 9.46957 18 10V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H10C9.46957 18 8.96086 17.7893 8.58579 17.4142C8.21071 17.0391 8 16.5304 8 16H6C6 17.0609 6.42143 18.0783 7.17157 18.8284C7.92172 19.5786 8.93913 20 10 20H16Z"
                fill="#9E741B"
              />
            </svg>
            <p className="resultText">
              Entering a channel requiring a whole new skill set
            </p>
          </div>
        
    )
}

export default CarouselItem4