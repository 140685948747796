import './App.scss';
import {
  BrowserRouter as Router,
  Switch, Route } from "react-router-dom";
import Home from './pages/home/home';  
import MeetOurExecutives from './pages/meetOurExecutives/meetOurExecutives';
import AboutPage from './pages/about/about';


function App() {
  
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/executives">
            <MeetOurExecutives />
          </Route>
          <Route path="/about">
            <AboutPage/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
