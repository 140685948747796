import React from 'react'
import './bookExecutiveCard.scss'
import ClickOutsideBookExecutiveCard from './clickOutsideBookExecutiveCard'

const BookExecutiveCard = ({setShowBookExecutiveCard, profileCard, execName}) => {

    return(
        <div className="bookExecutiveCardContainer">
            <ClickOutsideBookExecutiveCard profileCard={profileCard} execName={execName} setShowBookExecutiveCard={setShowBookExecutiveCard} />
        </div>
    )
}

export default BookExecutiveCard