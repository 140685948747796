import React, { useState } from "react";
import "./registerExecutiveCard.scss";
import close from "../../assets/close.png";
import axios from "axios";
import registration from "../../assets/registration.png";
import onClickOutside from "react-onclickoutside";
import PhoneInput from "react-phone-number-input";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function ClickOutsideRegisterExecutiveCard({ setShowRegisterCard }) {
  ClickOutsideRegisterExecutiveCard.handleClickOutside = () =>
    setShowRegisterCard(false);

  const [isError, setIsError] = useState(false);

  const [title, setTitle] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [male, setMale] = useState(null);
  const [female, setFemale] = useState(null);
  const [otherGender, setOtherGender] = useState(null);
  const [specifyGender, setSpecifyGender] = useState("");
  const [notSayingGender, setNotSayingGender] = useState(null);
  const [image, setImage] = useState(null);
  const [cv, setCv] = useState(null);
  const [email, setEmail] = useState(null);
  const [cellphone, setCellphone] = useState(null);
  const [skype, setSkype] = useState(null);
  const [linkedin, setLinkedin] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [summary, setSummary] = useState(null);
  const [coreArea, setCoreArea] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [daily, setDaily] = useState("");
  const [hourly, setHourly] = useState("");
  const [monthly, setMonthly] = useState("");
  const [other, setOther] = useState("");
  const [specifyTime, setSpecifytime] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitFail, setSubmitFail] = useState(false);
  const [processing, setProcessing] = useState(false)
  const notify = () => {
    setIsError(true);
  };
  const register = () => {
    //e.preventDefault()
    setProcessing(true)
    setIsError(false);
    let data = new FormData();
    data.append("title", title);
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("gender",  `${female} ${male} ${specifyGender} ${notSayingGender}`);
    data.append("file", image);
    data.append("cv", cv);
    data.append("email", email);
    data.append("cellphone", cellphone);
    data.append("skype", skype);
    data.append("linkedin", linkedin);
    data.append("city", city);
    data.append("country", country);
    data.append("summary", summary);
    data.append("core_area", coreArea);
    data.append("industries", industries);
    data.append(
      "hours_available",
      `${hourly} ${daily} ${monthly}  ${specifyTime}`
    );
    console.log({data})
    axios({
      method: "POST",
      url: "https://gig-office-mail.herokuapp.com/",
      data,
    })
      .then((response) => {
        console.log(response);
        setSubmitSuccess(true);
        setProcessing(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitFail(true);
        setProcessing(false);
      });
  };

  return (
    <div className="registerExecutiveCardElementsContainer">
      <div className="goBackButton">
        <img
          onClick={() => setShowRegisterCard(false)}
          src={close}
          alt="Back Arrow"
        />
      </div>
      <div className="registerFormContainer">
        <p className="formHeading">
          <img src={registration} alt="dggd" />
          Register To Be An Executive
        </p>
        {/* <form> */}
        <div className="inputItem">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title e.g Dr, Mr, Mrs etc"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>First Name</label>
          <input
            type="text"
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        {/* <div className="inputItemDate">
          <label>Birthday</label>
          <DatePicker onChange={setBirthday} value={birthday} />
        </div> */}
          <p className="genderHeading">Gender</p>
          <label className="radio">
            Female
            <input value={female} onChange={e=> {setFemale(e.target.checked? "Female": ''); setOtherGender(''); setMale(''); setNotSayingGender('')}} type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          <label className="radio">
            Male
            <input value={male} onChange={e=> {setMale(e.target.checked? "Male": ''); setOtherGender(''); setFemale(''); setNotSayingGender('')}} type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          <label className="radio">
            Other
            <input value={otherGender}  onChange={e=> {setOtherGender(e.target.checked? "Other Gender": ''); setMale(''); setFemale(''); setNotSayingGender('')} } type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>
          {otherGender && <input value={specifyGender} onChange={e=> setSpecifyGender(e.target.value)} style={{marginBottom: "15px", borderRadius: "5px", width:"50%", height:"35px",  border: "1px solid #f0e4d1"}}/>}
          <label className="radio">
            Prefer not to say
            <input  value={notSayingGender} onChange={e=> {setNotSayingGender(e.target.checked? "Prefer not to say": ''); setMale(''); setFemale(''); setOtherGender('')}} type="radio" name="radio" />
            <span className="checkmark"></span>
          </label>

        <div style={{marginTop: "20px"}} className="inputItem">
          <label>Email</label>
          <input
            type="text"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Cellphone</label>
          <PhoneInput
            placeholder="Enter your mobile number"
            value={cellphone}
            onChange={setCellphone}
            withCountryCallingCode
            error={true}
          />
        </div>

        <div className="inputItem">
          <label>Skype</label>
          <input
            type="text"
            placeholder="Skype"
            onChange={(e) => setSkype(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Linkedin</label>
          <input
            type="text"
            placeholder="Paste LinkedIn url."
            onChange={(e) => setLinkedin(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>City</label>
          <input
            type="text"
            placeholder="City"
            onChange={(e) => setCity(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Country</label>
          <input
            type="text"
            placeholder="Country"
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Summary</label>
          <textarea
            type="text"
            placeholder="Can you tell us a bit about yourself?"
            rows="4"
            onChange={(e) => setSummary(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Core Area</label>
          <input
            type="text"
            placeholder="Can you tell us which areas of expertise you are in?"
            onChange={(e) => setCoreArea(e.target.value)}
          />
        </div>

        <div className="inputItem">
          <label>Industries</label>
          <input
            type="text"
            placeholder="Can you tell us which industries you have experience in?"
            onChange={(e) => setIndustries(e.target.value)}
          />
        </div>
        <div className="checkbox-container">
          <label className="checkboxHeading">Availability</label>
          {/* <div className="inputItemCheckbox"> */}
            <label className="radio">Hourly
            <input
              name="availabilityRadio"
              value={hourly}
              className="larger"
              type="radio"
              placeholder="Hourly"
              onChange={(e) =>
                {setHourly(e.target.checked ? "I am available hourly" : ""); setOther(false)}
              }
            />
            <span className="checkmark"></span>
            </label>
            <label className="radio">Daily
            <input
              name="availabilityRadio"
              value={daily}
              className="larger"
              type="radio"
              placeholder="Daily"
              onChange={(e) =>
                {setDaily(e.target.checked ? "I am available daily" : ""); setOther(false)}
              }
            />
            <span className="checkmark"></span>
            </label>
            <label className="radio">Monthly
            <input
              name="availabilityRadio"
              value={monthly}
              className="larger"
              type="radio"
              placeholder="Monthly"
              onChange={(e) =>
                {setMonthly(e.target.checked ? "I am available Monthly" : ""); setOther(false)}
              }
            />
            <span className="checkmark"></span>
            </label>
            <label className="radio">Other
            <input
              name="availabilityRadio"
              value={other}
              className="larger"
              type="radio"
              placeholder="Other"
              onChange={(e) =>
                setOther(e.target.checked)
              }
            />
            <span className="checkmark"></span>
            </label>
          {/* </div> */}
        </div>
        {other && <input value={specifyTime} onChange={e=> setSpecifytime(e.target.value)} style={{marginBottom: "15px", borderRadius: "5px", width:"50%", height:"35px",  border: "1px solid #f0e4d1"}}/>}

        <div className="inputItem">
          <label>High Quality Image</label>
          <input
            type="file"
            accept="image/*"
            placeholder="Profile Picture"
            className="pictureInput"
            onChange={(e) => setImage(e.target.files[0])}
          />
        </div>

        <div className="inputItem">
          <label>CV</label>
          <input
            type="file"
            placeholder="CV"
            className="pictureInput"
            onChange={(e) => setCv(e.target.files[0])}
          />
        </div>

        { processing ? <Loader className="spinner"
        type="Puff"
        color="#9E741B"
        height={70}
        width={70}
      /> : null}

        {submitSuccess ? (
          <p className="successText">Application submitted successfully!</p>
        ) : null}

        {submitFail ? (
          <p className="failText">
            Oh no! Something went wrong with your submission. Please try again
            later.
          </p>
        ) : null}

        <button
          id="regButton"
          type="submit"
          onClick={() =>
            firstName &&
            title &&
            lastName &&
            email &&
            cellphone &&
            skype &&
            linkedin &&
            city &&
            country &&
            summary &&
            coreArea &&
            industries
              ? register()
              : notify()
          }
        >
          Register
        </button>
        {isError && <p style={{ color: "red" }}>Fill in the entire form</p>}
        {/* </form> */}
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () =>
    ClickOutsideRegisterExecutiveCard.handleClickOutside,
};

export default onClickOutside(
  ClickOutsideRegisterExecutiveCard,
  clickOutsideConfig
);
