import React, {useState} from 'react'
import './footer.scss'
import { Link } from "react-router-dom"
import logo from '../../assets/footer/logo.png'
import RegisterExecutiveCard from '../registerToBecomeAnExecutive/registerExecutiveCard'

const Footer = () => {
    const [showRegisterCard, setShowRegisterCard] = useState(false)

    return(<div className="footerContainer">
        <div className="footerColumnsContainer">
            <div className="logoAndLinksRow">
            <div className="logoColumnContainer">
                <img src={logo} alt="Gig Office Logo"/>
            </div> 

            <div className="quickLinksColumnContainer">
                <p className="columnHeader">Quick Links</p>
                <div className="linksColumnContainer">
                <Link to="/about"><p>About</p></Link>
                <Link to="/executives"><p>Meet our Executives</p></Link>
                <div  onClick={() => setShowRegisterCard(true)} className="goToRegisterLink">
                   Join our Executive Network
                    </div>
                </div>
            </div>
            </div>
            
            <div className="socialMediaColumnContainer">
                <p className="columnHeader">Social Media</p>
                <div className="socialElementsContainer">
                    <p>Follow us on our social media channels for all latest news</p>
                    <div className="socialMediaIcons">
                        <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M39.999 3.7905C38.8851 5.42222 37.5186 6.81318 35.8993 7.96339C35.9153 8.27187 35.9233 8.62053 35.9233 9.00938C35.9233 11.1699 35.6035 13.333 34.9638 15.4987C34.3242 17.6644 33.347 19.7374 32.0325 21.7177C30.718 23.6979 29.1521 25.4522 27.3349 26.9806C25.5177 28.509 23.3334 29.7277 20.7819 30.6366C18.2304 31.5456 15.4959 32 12.5784 32C8.0251 32 3.83197 30.7894 -0.00100708 28.3683C0.679301 28.4428 1.33177 28.4801 1.95641 28.4801C5.76031 28.4801 9.15781 27.3262 12.1489 25.0184C10.3753 24.9864 8.78692 24.4487 7.38374 23.4053C5.98056 22.362 5.01492 21.0288 4.48683 19.4057C5.00882 19.5036 5.524 19.5526 6.03237 19.5526C6.76415 19.5526 7.48421 19.4586 8.19254 19.2706C6.29979 18.8973 4.72959 17.9689 3.48194 16.4853C2.23433 15.0018 1.61053 13.2896 1.61053 11.3486V11.2486C2.77231 11.8821 4.01153 12.2191 5.3282 12.2595C4.20694 11.5233 3.31796 10.5634 2.66124 9.37955C2.00448 8.19574 1.6761 6.91466 1.6761 5.53629C1.6761 4.08257 2.04598 2.72889 2.78575 1.47523C4.84409 3.96285 7.3382 5.95103 10.2681 7.43978C13.198 8.92854 16.3412 9.75536 19.6978 9.92022C19.5546 9.33264 19.4829 8.71947 19.4827 8.0807C19.4827 5.85039 20.2841 3.94603 21.8869 2.36762C23.4898 0.789205 25.4235 0 27.6882 0C30.0592 0 32.0563 0.850251 33.6796 2.55075C35.5342 2.18896 37.2707 1.53457 38.8892 0.587584C38.2656 2.52017 37.0641 4.01103 35.2847 5.06016C36.9202 4.86876 38.4916 4.44554 39.999 3.7905H39.999Z" fill="black"/>
                        </svg>

                        <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.3867 33.2511H0.545089V11.0523H7.3867V33.2511ZM3.96221 8.02414C1.77449 8.02414 0 6.19835 0 3.99406C1.56588e-08 2.93525 0.417446 1.91981 1.1605 1.17113C1.90356 0.422439 2.91137 0.00183105 3.96221 0.00183105C5.01305 0.00183105 6.02086 0.422439 6.76392 1.17113C7.50697 1.91981 7.92442 2.93525 7.92442 3.99406C7.92442 6.19835 6.1492 8.02414 3.96221 8.02414ZM32.9926 33.2511H26.1658V22.4448C26.1658 19.8694 26.1142 16.5667 22.6087 16.5667C19.0516 16.5667 18.5065 19.3648 18.5065 22.2593V33.2511H11.6723V11.0523H18.234V14.0804H18.3297C19.2431 12.3362 21.4743 10.4956 24.803 10.4956C31.7271 10.4956 33 15.0898 33 21.0569V33.2511H32.9926Z" fill="black"/>
                        </svg>

                        <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9167 1.54175C17.9167 1.33179 17.8333 1.13042 17.6848 0.981959C17.5363 0.833492 17.335 0.750085 17.125 0.750085H13.1667C11.1734 0.650795 9.22195 1.34445 7.73856 2.6795C6.25517 4.01455 5.36049 5.88245 5.25 7.87508V12.1501H1.29167C1.0817 12.1501 0.88034 12.2335 0.731874 12.382C0.583408 12.5304 0.5 12.7318 0.5 12.9418V17.0584C0.5 17.2684 0.583408 17.4697 0.731874 17.6182C0.88034 17.7667 1.0817 17.8501 1.29167 17.8501H5.25V28.4584C5.25 28.6684 5.33341 28.8697 5.48187 29.0182C5.63034 29.1667 5.8317 29.2501 6.04167 29.2501H10.7917C11.0016 29.2501 11.203 29.1667 11.3515 29.0182C11.4999 28.8697 11.5833 28.6684 11.5833 28.4584V17.8501H15.7317C15.9077 17.8526 16.0796 17.7964 16.2201 17.6903C16.3606 17.5842 16.4617 17.4343 16.5075 17.2643L17.6475 13.1476C17.679 13.0306 17.6832 12.908 17.6599 12.7891C17.6365 12.6702 17.5862 12.5583 17.5128 12.462C17.4394 12.3656 17.3448 12.2874 17.2364 12.2333C17.128 12.1792 17.0086 12.1508 16.8875 12.1501H11.5833V7.87508C11.6227 7.48317 11.8068 7.12001 12.0995 6.85652C12.3923 6.59302 12.7728 6.44811 13.1667 6.45008H17.125C17.335 6.45008 17.5363 6.36668 17.6848 6.21821C17.8333 6.06974 17.9167 5.86838 17.9167 5.65842V1.54175Z" fill="black"/>
                        </svg>
                    </div>
                    <p className="copyright">Copyright &copy; 2022 | Gig Office | All Rights Reserved</p>
                </div>
            </div>
        </div>
        {showRegisterCard ? <RegisterExecutiveCard setShowRegisterCard={setShowRegisterCard} /> : null}
    </div>)
} 

export default Footer