import React, {useState, useLayoutEffect} from "react";
import Footer from "../../components/footer/footer";
import NavBar from "../../components/navBar/navBarMeet";
import ExecutiveCard from "../../components/executiveCard/executiveCard";
import "./meetOurExecutives.scss";
import { useSelector } from "react-redux";
import RegisterExecutiveCard from "../../components/registerToBecomeAnExecutive/registerExecutiveCard";

const MeetOurExecutives = () => {
  const exec = useSelector((state) => state.ExecutivesListStateReducer);
  const [search, setSearch] = useState("")
  const [showRegisterCard, setShowRegisterCard] = useState(false)

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className="meetOurExecutivesPageContainer">
      <div className="meetOurExecutivesPageElementsContainer">
        <NavBar />
        <div className="meetOurExecutivesPageBodyContainer">
          <p className="pageHeading">
            Access the best executive expertise to drive organizational success
          </p>

          <p className="introductoryText">
            Our executives serve business builders like you to achieve their
            goals. We are trusted by companies of all sizes to meet their needs.
          </p>

          <p className="introductoryText">
            Today's business challenges are more complex than ever before. As a
            business leader you must continually adapt your product or service
            to meet the needs of today's marketplace, while delivering a solid
            return of investment.
          </p>

          <p className="introductoryText">
            Have you considered bringing in a fractional executive to help you
            achieve success, and build growth engines within your organization?
            Our executives are experienced professionals who have navigated a
            challenge you are experienced, and have achieved objectives that you
            would like to achieve. If you seek expert guidance also, our
            executives can play that role for your organization.
          </p>

          <div className="searchBarContainer">
            <span>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
            <input
              type="text"
              name="search"
              placeholder="e.g finance, operations..."
              value={search}
              onChange={(e)=>{
                console.log({search})  
                setSearch(e.target.value)}}
            />
          </div>
        </div>
        <div className="executives-grid-container">
          <div className="parent">
            {exec.executives
              .filter((e) =>
                e.search.toLowerCase().includes(search?.toLowerCase())
              ).map((executive, index) => (
              <ExecutiveCard
              key={index}
                name={executive.name}
                bio={executive.bio}
                picture={executive.image}
                title={executive.title}
                areas={executive.areas}
                sectors={executive.sectors}
                speciality={executive.speciality}
              />
            ))}
          </div>
        </div>
        <div className="registerExecutiveButtonContainer">
          <button onClick={() => setShowRegisterCard(true)}>Register to be an executive</button>
        </div>
        {showRegisterCard ? <RegisterExecutiveCard setShowRegisterCard={setShowRegisterCard} /> : null}
        <Footer />
      </div>
    </div>
  );
};
export default MeetOurExecutives;