import "./howToHireAnExecutive.scss";
import match from "../../assets/executiveMatch.jpg";

const StepThree = () => {
  return (
    <div className="greySelectionStep">
      <div className="desktopUI">
      <img src={match} alt="match" />
      <div>
        <p className="selectionStepNumber">Step 3.</p>
        <p className="selectionStepHeading">Executive Match</p>
        <p className="selectionStepDescription">
          We help match your situation and requirements with one or more of our
          executives, working with you to ensure the right expertise,
          availability, affordability and cultural fit.
        </p>
      </div>
      </div>
      <div className="mobileUI">
        <div>
          <p className="selectionStepNumber">Step 3.</p>
          <p className="selectionStepHeading">Executive Match</p>
        </div>
        <img src={match} alt="match" />
        <p className="selectionStepDescription">
          We help match your situation and requirements with one or more of our
          executives, working with you to ensure the right expertise,
          availability, affordability and cultural fit.
        </p>
      </div>
    </div>
  );
};

export default StepThree;
