import React, {useState} from 'react'
import './homeHero.scss'
import { Link } from "react-router-dom"
import exec3 from '../../assets/executives/exec-sora.jpg'
import exec from '../../assets/executives/exec-ment.jpg'
import exec2 from '../../assets/executives/exec.jpg'
import exec4 from '../../assets/executives/exec3.jpg'
import RegisterExecutiveCard from '../registerToBecomeAnExecutive/registerExecutiveCard'

const HomeHero = () => {

    const [showRegisterCard, setShowRegisterCard] = useState(false)

    return(
        <div className="homeHeroContainer">
            <div className="firstRow">
            <div className="leftTextSection">
                <p className="homeHeroHeading">Where business<br/>leaders meet<br/>the 
                    best fractional<br/>executives in Africa.</p>
            </div>
            <div className="rightImageSection">
                <div className={["executiveImagesColumn", "firstColumn"].join(" ")}>
                    <img className='img-border' alt="dp" src={exec}/>
                    <img className='img-border' alt="dp" src={exec2}/>
                </div>
                <div className="executiveImagesColumn">
                    <img className='img-border' alt="dp" src={exec3}/>
                    <img className='img-border' alt="dp" src={exec4}/>
                </div>
            </div>
            </div>
            <div className="buttonRow">
                <div className="meet">
                    <button>
                    <Link to="/executives">Meet our Executives</Link>
                    </button>
                </div>
                <div className="join">
                    <button onClick={() => setShowRegisterCard(true)}>
                    Join our Executive Network
                    </button>
                </div>
            </div>
            <div className="mobileExecutiveImages">
                <div className="executiveImagesRow">
                    <img className='img-border' alt="dp" src={exec3}/>
                    <img className='img-border' alt="dp" src={exec4}/>
                </div>
            </div>
            {showRegisterCard ? <RegisterExecutiveCard setShowRegisterCard={setShowRegisterCard} /> : null}
        </div>
    )
}

export default HomeHero