import "./howToHireAnExecutive.scss";
import interest from '../../assets/expressionOfInt.jpg'

const StepOne = () => {
  return (
    <div className="greySelectionStep">
      <div className="desktopUI">
        <img src={interest} alt="interest"/>
      <div>
        <p className="selectionStepNumber">Step 1.</p>
        <p className="selectionStepHeading">Expression of Interest</p>
        <p className="selectionStepDescription">
          Fill in a form outlining your needs and requirements, and we will
          follow-up with next steps via a call or email.
        </p>
      </div>
      </div>
      <div className="mobileUI">
        <div>
          <p className="selectionStepNumber">Step 1.</p>
          <p className="selectionStepHeading">Expression of Interest</p>
        </div>
        <img src={interest} alt="interest"/>
        <p className="selectionStepDescription">
          Fill in a form outlining your needs and requirements, and we will
          follow-up with next steps via a call or email.
        </p>
      </div>
    </div>
  );
};

export default StepOne;
