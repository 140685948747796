import "./howToHireAnExecutive.scss";
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import StepFour from "./stepFour";
import StepFive from "./stepFive";

const HowToHireAnExecutive = () => {

 return(
    <Splide
          options={{
            speed: 2000,
            interval: 3000,
            pauseOnHover: true,
            rewind: true,
          }}
        >
          <SplideSlide>
            <StepOne/>
          </SplideSlide>
          <SplideSlide>
          <StepTwo/>
          </SplideSlide>
          <SplideSlide>
          <StepThree/>
          </SplideSlide>
          <SplideSlide>
          <StepFour/>
          </SplideSlide>
          <SplideSlide>
          <StepFive/>
          </SplideSlide>
        </Splide>

 )
};
export default HowToHireAnExecutive;
